import { colors } from '../colors';
import {
  blackBase,
  blackAlpha100,
  blackAlpha200,
  blackAlpha300,
  blackAlpha400,
  blackAlpha500,
  blackAlpha600,
  blackAlpha700,
  blackAlpha800,
  blackAlpha900,
  whiteBase,
  whiteAlpha100,
  whiteAlpha200,
  whiteAlpha300,
  whiteAlpha400,
  whiteAlpha500,
  whiteAlpha600,
  whiteAlpha700,
  whiteAlpha800,
  whiteAlpha900,
  gray500,
  gray600,
  gray800,
  gray1400,
  blue50,
  blue100,
  blue200,
  blue500,
  blue600,
  blue700,
  blue800,
  green200,
  green300,
  green400,
  green500,
  green600,
  green700,
  green800,
  coral50,
  coral100,
  coral200,
  coral600,
  coral700,
  coral800,
  purple600,
  purple800,
  yellow500,
  yellow200,
  yellow300,
  yellow400,
} from '../palette';
import { dark } from './dark';
import type { ThemesT } from './theme.types';

/** IMPORTANT! Any changes to themes needs to be reflected in tailwind!
 * 1. Apply the same changes to the respective theme in kosmosTokensToPaletteMap.ts, in playground-web
 * 2. Run localhost:1000/tailwind (playground-web) with the theme settings and use "console.log(colorTokenMap)"
 * 3. Update the theme class in globals.css
 */
export const themes: ThemesT = {
  light: {
    colors: {
      ...colors,
    },
  },
  dark: { colors: dark },
  primary: {
    colors: {
      ...colors,
      bg: blue500,
      bgStrong: blackAlpha200,
      surface: whiteAlpha100,
      surfaceRaised: whiteAlpha200,
      surfaceSunken: blackAlpha200,
      surfaceOverlay: whiteAlpha100,
      border: whiteAlpha500,
      borderWeak: whiteAlpha300,
      borderWeaker: whiteAlpha300,
      borderStrong: whiteAlpha600,
      borderDisabled: whiteAlpha300,
      onBrand: blue800,
      onBrandWeakest: whiteBase,
      onDisabled: blackAlpha500,
      onDisabledStrong: blackAlpha800,
      onPrimary: gray1400,
      primary: whiteBase,
      primaryWeakest: whiteAlpha500,
      disabled: whiteAlpha100,
      disabledStrong: whiteAlpha400,
      secondary: blue800,
      text: whiteBase,
      textWeak: whiteBase,
      textWeaker: whiteBase,
      textDisabled: whiteAlpha400,
      textLink: whiteBase,
      textPrimary: whiteBase,
      interactiveFocused: whiteAlpha700,
      // COMPONENT TOKENS
      buttonBgOutlineHover: blackAlpha200,
      buttonBgOutlineActive: blackAlpha400,
      buttonBgOutlineChecked: blackAlpha500,
      buttonBgOutlineCheckedHover: blackAlpha600,
      buttonBgOutlineCheckedActive: blackAlpha700,
      buttonBgBrand: whiteBase,
      buttonBgBrandHover: whiteAlpha900,
      buttonBgBrandActive: whiteAlpha700,
      buttonBgPrimary: blue50,
      buttonBgPrimaryHover: blue100,
      buttonBgPrimaryActive: blue200,
      buttonBgPrimaryChecked: blue800,
      buttonBgPrimaryCheckedHover: blue800,
      buttonBgPrimaryCheckedActive: blue800,
      buttonBgPrimaryReverse: blue500,
      buttonBgPrimaryReverseHover: blue600,
      buttonBgPrimaryReverseActive: blue700,
      buttonBgPrimaryReverseChecked: blue800,
      buttonBgPrimaryReverseCheckedHover: blue800,
      buttonBgPrimaryReverseCheckedActive: blue800,
      buttonBgSecondaryHover: whiteAlpha400,
      buttonBgSecondaryActive: whiteAlpha500,
      buttonBgSecondaryChecked: blackAlpha500,
      buttonBgSecondaryCheckedHover: blackAlpha600,
      buttonBgSecondaryCheckedActive: blackAlpha700,
      buttonBgTertiary: blue600,
      buttonBgTertiaryHover: blue700,
      buttonBgTertiaryActive: blue800,
      buttonBgTertiaryChecked: blue800,
      buttonBgTertiaryCheckedHover: blue800,
      buttonBgTertiaryCheckedActive: blue800,
      inputBg: whiteAlpha300,
      inputBgActive: blue500,
    },
  },
  negative: {
    colors: {
      ...colors,
      bg: coral600,
      bgStrong: blackAlpha200,
      surface: whiteAlpha100,
      surfaceRaised: whiteAlpha200,
      surfaceSunken: blackAlpha200,
      surfaceOverlay: whiteAlpha100,
      border: whiteAlpha500,
      borderWeak: whiteAlpha300,
      borderWeaker: whiteAlpha200,
      borderStrong: whiteAlpha600,
      borderDisabled: whiteAlpha300,
      text: whiteBase,
      textWeak: whiteAlpha900,
      textWeaker: whiteAlpha800,
      textDisabled: whiteAlpha400,
      textLink: whiteBase,
      textPrimary: whiteBase,
      onBrand: gray1400,
      onPrimary: coral800,
      onBrandWeakest: whiteBase,
      onDisabled: whiteAlpha400,
      onDisabledStrong: whiteAlpha600,
      neutral: whiteAlpha300,
      neutralWeakest: whiteAlpha200,
      primary: whiteBase,
      disabled: whiteAlpha100,
      disabledStrong: whiteAlpha200,
      onNeutral: whiteBase,
      secondary: coral800,
      interactiveFocused: whiteAlpha700,
      // COMPONENT TOKENS
      buttonBgBrand: whiteBase,
      buttonBgBrandHover: whiteAlpha900,
      buttonBgBrandActive: whiteAlpha700,
      buttonBgOutlineHover: blackAlpha200,
      buttonBgOutlineActive: blackAlpha400,
      buttonBgOutlineChecked: blackAlpha500,
      buttonBgOutlineCheckedHover: blackAlpha600,
      buttonBgOutlineCheckedActive: blackAlpha700,
      buttonBgPrimary: coral50,
      buttonBgPrimaryHover: coral100,
      buttonBgPrimaryActive: coral200,
      buttonBgPrimaryChecked: coral800,
      buttonBgPrimaryCheckedHover: coral800,
      buttonBgPrimaryCheckedActive: coral800,
      buttonBgPrimaryReverse: coral600,
      buttonBgPrimaryReverseHover: coral700,
      buttonBgPrimaryReverseActive: coral800,
      buttonBgPrimaryReverseChecked: coral800,
      buttonBgPrimaryReverseCheckedHover: coral800,
      buttonBgPrimaryReverseCheckedActive: coral800,
      buttonBgSecondaryHover: whiteAlpha400,
      buttonBgSecondaryActive: whiteAlpha500,
      buttonBgSecondaryChecked: blackAlpha500,
      buttonBgSecondaryCheckedHover: blackAlpha600,
      buttonBgSecondaryCheckedActive: blackAlpha700,
      buttonBgTertiary: coral600,
      buttonBgTertiaryHover: coral700,
      buttonBgTertiaryActive: coral800,
      buttonBgTertiaryChecked: coral800,
      buttonBgTertiaryCheckedHover: coral800,
      buttonBgTertiaryCheckedActive: coral800,
      inputBg: whiteAlpha300,
      inputBgActive: coral600,
    },
  },
  notice: {
    colors: {
      ...colors,
      bg: yellow500,
      bgStrong: blackAlpha200,
      surface: whiteAlpha100,
      surfaceRaised: whiteAlpha200,
      surfaceSunken: blackAlpha200,
      surfaceOverlay: whiteAlpha100,
      border: blackAlpha500,
      borderWeak: blackAlpha300,
      borderWeaker: blackAlpha200,
      borderStrong: blackAlpha600,
      borderDisabled: blackAlpha300,
      textWeaker: blackAlpha800,
      textDisabled: blackAlpha400,
      textLink: blackBase,
      textPrimary: blackBase,
      onBrand: whiteBase,
      onBrandWeakest: blackBase,
      onDisabled: blackAlpha400,
      onDisabledStrong: blackAlpha600,
      onPrimary: whiteBase,
      neutral: blackAlpha300,
      neutralWeakest: blackAlpha200,
      primary: gray1400,
      disabled: blackAlpha100,
      disabledStrong: blackAlpha200,
      onNeutral: blackBase,
      secondary: gray1400,
      interactiveFocused: blackAlpha700,
      // COMPONENT TOKENS
      buttonBgOutlineChecked: yellow200,
      buttonBgOutlineCheckedHover: yellow300,
      buttonBgOutlineCheckedActive: yellow400,
      buttonBgSecondaryChecked: yellow200,
      buttonBgSecondaryCheckedHover: yellow300,
      buttonBgSecondaryCheckedActive: yellow400,
      inputBg: blackAlpha300,
      inputBgActive: yellow500,
    },
  },
  positive: {
    colors: {
      ...colors,
      bg: green500,
      bgStrong: blackAlpha200,
      surface: whiteAlpha100,
      surfaceRaised: whiteAlpha200,
      surfaceSunken: blackAlpha200,
      surfaceOverlay: whiteAlpha100,
      border: blackAlpha500,
      borderWeak: blackAlpha300,
      borderWeaker: blackAlpha300,
      borderStrong: blackAlpha600,
      borderDisabled: blackAlpha300,
      textDisabled: blackAlpha400,
      textWeaker: blackAlpha800,
      textLink: blackBase,
      textPrimary: blackBase,
      onBrandWeakest: blackBase,
      onDisabled: blackAlpha400,
      onDisabledStrong: blackAlpha600,
      onPrimary: whiteBase,
      neutral: blackAlpha300,
      neutralWeakest: blackAlpha200,
      primary: gray1400,
      disabled: blackAlpha100,
      disabledStrong: blackAlpha200,
      onNeutral: blackBase,
      secondary: gray1400,
      interactiveFocused: blackAlpha700,
      // COMPONENT TOKENS
      buttonBgOutlineChecked: green200,
      buttonBgOutlineCheckedHover: green300,
      buttonBgOutlineCheckedActive: green400,
      buttonBgPrimaryChecked: green600,
      buttonBgPrimaryCheckedHover: green700,
      buttonBgPrimaryCheckedActive: green800,
      buttonBgPrimaryReverseChecked: green600,
      buttonBgPrimaryReverseCheckedHover: green700,
      buttonBgPrimaryReverseCheckedActive: green800,
      buttonBgSecondaryChecked: green200,
      buttonBgSecondaryCheckedHover: green300,
      buttonBgSecondaryCheckedActive: green400,
      buttonBgTertiaryChecked: green600,
      buttonBgTertiaryCheckedHover: green700,
      buttonBgTertiaryCheckedActive: green800,
      inputBg: blackAlpha300,
      inputBgActive: green500,
    },
  },
  info: {
    colors: {
      ...colors,
      bg: purple600,
      bgStrong: blackAlpha200,
      surface: whiteAlpha100,
      surfaceRaised: whiteAlpha200,
      surfaceSunken: blackAlpha200,
      surfaceOverlay: whiteAlpha100,
      border: whiteAlpha500,
      borderWeak: whiteAlpha300,
      borderWeaker: whiteAlpha300,
      borderStrong: whiteAlpha600,
      borderDisabled: whiteAlpha300,
      onBrand: gray1400,
      onBrandWeakest: whiteBase,
      onDisabled: whiteAlpha400,
      onDisabledStrong: whiteAlpha600,
      neutralWeakest: blackAlpha200,
      disabled: whiteAlpha100,
      disabledStrong: whiteAlpha200,
      secondary: gray1400,
      interactiveFocused: whiteAlpha700,
      // COMPONENT TOKENS
      buttonBgBrand: whiteBase,
      buttonBgBrandHover: whiteAlpha900,
      buttonBgBrandActive: whiteAlpha700,
      buttonBgOutlineHover: whiteAlpha200,
      buttonBgOutlineActive: whiteAlpha300,
      buttonBgPrimaryChecked: purple800,
      buttonBgPrimaryCheckedHover: purple800,
      buttonBgPrimaryCheckedActive: purple800,
      buttonBgPrimaryReverseChecked: purple800,
      buttonBgPrimaryReverseCheckedHover: purple800,
      buttonBgPrimaryReverseCheckedActive: purple800,
      buttonBgSecondaryChecked: whiteAlpha200,
      buttonBgSecondaryCheckedHover: whiteAlpha300,
      buttonBgSecondaryCheckedActive: whiteAlpha400,
      buttonBgTertiaryChecked: purple800,
      buttonBgTertiaryCheckedHover: purple800,
      buttonBgTertiaryCheckedActive: purple800,
      inputBg: whiteAlpha300,
      inputBgActive: purple600,
    },
  },
  neutral: {
    colors: {
      ...colors,
      bg: gray500,
      bgStrong: blackAlpha200,
      surface: whiteAlpha100,
      surfaceRaised: whiteAlpha200,
      surfaceSunken: blackAlpha200,
      surfaceOverlay: whiteAlpha100,
      border: blackAlpha500,
      borderWeak: blackAlpha300,
      borderWeaker: blackAlpha300,
      borderStrong: blackAlpha600,
      borderDisabled: blackAlpha300,
      textWeak: blackAlpha900,
      textWeaker: blackAlpha800,
      textDisabled: blackAlpha400,
      textLink: blackBase,
      textPrimary: blackBase,
      onBrandWeakest: blackBase,
      onDisabled: blackAlpha400,
      onDisabledStrong: blackAlpha600,
      neutralWeakest: blackAlpha200,
      primary: gray1400,
      primaryWeakest: blackAlpha500,
      neutral: gray600,
      disabled: blackAlpha100,
      disabledStrong: blackAlpha200,
      onNeutral: blackBase,
      secondary: gray800,
      interactiveFocused: blackAlpha700,
      // COMPONENT TOKENS
      inputBg: blackAlpha300,
      inputBgActive: gray500,
    },
  },
};
