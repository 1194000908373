import { alpha } from './utils';

// Black Alpha
export const blackBase = '#000000';
export const blackAlpha50 = alpha(blackBase, 0.02);
export const blackAlpha100 = alpha(blackBase, 0.05);
export const blackAlpha200 = alpha(blackBase, 0.1);
export const blackAlpha300 = alpha(blackBase, 0.14);
export const blackAlpha400 = alpha(blackBase, 0.2);
export const blackAlpha500 = alpha(blackBase, 0.3);
export const blackAlpha600 = alpha(blackBase, 0.45);
export const blackAlpha700 = alpha(blackBase, 0.55);
export const blackAlpha800 = alpha(blackBase, 0.65);
export const blackAlpha900 = alpha(blackBase, 0.75);
export const blackAlpha1000 = alpha(blackBase, 0.85);

// White Alpha
export const whiteBase = '#ffffff';
export const whiteAlpha50 = alpha(whiteBase, 0.02);
export const whiteAlpha100 = alpha(whiteBase, 0.05);
export const whiteAlpha200 = alpha(whiteBase, 0.1);
export const whiteAlpha300 = alpha(whiteBase, 0.14);
export const whiteAlpha400 = alpha(whiteBase, 0.2);
export const whiteAlpha500 = alpha(whiteBase, 0.3);
export const whiteAlpha600 = alpha(whiteBase, 0.45);
export const whiteAlpha700 = alpha(whiteBase, 0.55);
export const whiteAlpha800 = alpha(whiteBase, 0.65);
export const whiteAlpha900 = alpha(whiteBase, 0.75);
export const whiteAlpha1000 = alpha(whiteBase, 0.85);

// Gray
export const grayBase = '#DBDCE1';
export const gray50 = '#FCFDFD';
export const gray100 = '#F8F8F9';
export const gray200 = '#EDEDF0';
export const gray300 = '#E8E8EB';
export const gray400 = '#E4E5E9';
export const gray500 = grayBase;
export const gray600 = '#BCBDC2';
export const gray700 = '#999A9D';
export const gray800 = '#6E6E71';
export const gray900 = '#58585A';
export const gray1000 = '#424244';
export const gray1050 = '#323233';
export const gray1100 = '#2C2C2D';
export const gray1200 = '#222222';
export const gray1300 = '#1A1A1B';
export const gray1400 = '#0F0F10';

// Blue Light
export const blueLightBase = '#9AADC2';
export const blueLight50 = '#2A2C2F';
export const blueLight100 = '#3A3E44';
export const blueLight200 = '#5A6673';
export const blueLight300 = '#7E8FA0';
export const blueLight400 = '#8E9FB2';
export const blueLight500 = blueLightBase;
export const blueLight600 = '#ADC0D6';
export const blueLight700 = '#CEDEEE';
export const blueLight800 = '#F0F5FA';

// Blue
export const blueBase = '#196CFF';
export const blue50 = '#222631';
export const blue100 = '#212E48';
export const blue200 = '#0D3680';
export const blue300 = '#124CB2';
export const blue400 = '#165DDB';
export const blue500 = blueBase;
export const blue600 = '#5391FF';
export const blue700 = '#6BA1FF';
export const blue800 = '#8DAFEB';

// Blue Dark
export const blueDarkBase = '#131A31';
export const blueDark50 = '#212125';
export const blueDark100 = '#1F2027';
export const blueDark200 = '#080C1A';
export const blueDark300 = '#0B1124';
export const blueDark400 = '#0E152D';
export const blueDark500 = blueDarkBase;
export const blueDark600 = '#4C5267';
export const blueDark700 = '#5A5F6F';
export const blueDark800 = '#898C98';

// Blue Alpha
export const blueAlpha50 = alpha(blueBase, 0.25);
export const blueAlpha100 = alpha(blueBase, 0.35);
export const blueAlpha500 = alpha(blueBase, 0.5);
export const blueAlpha700 = alpha(blueBase, 0.6);
export const blueAlpha900 = alpha(blueBase, 0.9);

// Blue Dark Alpha
const blueDarkAlphaBase = '#0F1834';
export const blueDarkAlpha50 = alpha(blueDarkAlphaBase, 0.25);
export const blueDarkAlpha100 = alpha(blueDarkAlphaBase, 0.35);
export const blueDarkAlpha500 = alpha(blueDarkAlphaBase, 0.5);
export const blueDarkAlpha700 = alpha(blueDarkAlphaBase, 0.75);
export const blueDarkAlpha900 = alpha(blueDarkAlphaBase, 0.9);

// Green Light
export const greenLightBase = '#9DD3B6';
export const greenLight50 = '#2B2E2E';
export const greenLight100 = '#3B4541';
export const greenLight200 = '#5C7D6B';
export const greenLight300 = '#81AF96';
export const greenLight400 = '#90C1A7';
export const greenLight500 = greenLightBase;
export const greenLight600 = '#B8EFD2';
export const greenLight700 = '#D2FCE5';
export const greenLight800 = '#E3FFF0';

// Green
export const greenBase = '#06BA7D';
export const green50 = '#202F2C';
export const green100 = '#1B473A';
export const green200 = '#006E49';
export const green300 = '#009966';
export const green400 = '#0AAD76';
export const green500 = greenBase;
export const green600 = '#40E4AD';
export const green700 = '#59E8B7';
export const green800 = '#80EDC8';

// Green Dark
export const greenDarkBase = '#115E3F';
export const greenDark50 = '#212726';
export const greenDark100 = '#1E312B';
export const greenDark200 = '#073623';
export const greenDark300 = '#094B30';
export const greenDark400 = '#105338';
export const greenDark500 = greenDarkBase;
export const greenDark600 = '#4A9074';
export const greenDark700 = '#629F86';
export const greenDark800 = '#86B5A2';

// Pink Light
export const pinkLightBase = '#FFB9D0';
export const pinkLight50 = '#312D30';
export const pinkLight100 = '#4E4046';
export const pinkLight200 = '#805D68';
export const pinkLight300 = '#B28192';
export const pinkLight400 = '#DB9FB3';
export const pinkLight500 = pinkLightBase;
export const pinkLight600 = '#FFC6D8';
export const pinkLight700 = '#FFD1E0';
export const pinkLight800 = '#FFDCE7';

// Pink
export const pinkBase = '#D76F93';
export const pink50 = '#2F272C';
export const pink100 = '#46313A';
export const pink200 = '#804056';
export const pink300 = '#B25A78';
export const pink400 = '#CD6387';
export const pink500 = pinkBase;
export const pink600 = '#DB7B9C';
export const pink700 = '#E39AB3';
export const pink800 = '#EBB7C9';

// Pink Dark
export const coralBase = '#FF403D';
export const coral50 = '#2F2425';
export const coral100 = '#462728';
export const coral200 = '#80201F';
export const coral300 = '#B22D2B';
export const coral400 = '#DB3734';
export const coral500 = coralBase;
export const coral600 = '#FF706E';
export const coral700 = '#FF8381';
export const coral800 = '#EB9D9B';

// Purple Light
export const purpleLightBase = '#B2B4D8';
export const purpleLight50 = '#2C2C31';
export const purpleLight100 = '#3F3F48';
export const purpleLight200 = '#696A80';
export const purpleLight300 = '#9294B2';
export const purpleLight400 = '#A2A4C3';
export const purpleLight500 = purpleLightBase;
export const purpleLight600 = '#DDDFFF';
export const purpleLight700 = '#E1E3FF';
export const purpleLight800 = '#E8E9FF';

// Purple
export const purpleBase = '#8186CF';
export const purple50 = '#292930';
export const purple100 = '#353646';
export const purple200 = '#4B4E7B';
export const purple300 = '#696DAB';
export const purple400 = '#7579BD';
export const purple500 = purpleBase;
export const purple600 = '#B0B5F7';
export const purple700 = '#BBBFF8';
export const purple800 = '#C0C3E7';

// Purple Alpha
export const purpleLightAlpha50 = alpha(purpleLightBase, 0.25);
export const purpleLightAlpha100 = alpha(purpleLightBase, 0.35);
export const purpleLightAlpha500 = alpha(purpleLightBase, 0.5);
export const purpleLightAlpha700 = alpha(purpleLightBase, 0.6);
export const purpleLightAlpha900 = alpha(purpleLightBase, 0.9);

// Purple Dark
export const purpleDarkBase = '#604BA1';
export const purpleDark50 = '#26252D';
export const purpleDark100 = '#2E2A3D';
export const purpleDark200 = '#372A5F';
export const purpleDark300 = '#4D3B84';
export const purpleDark400 = '#544090';
export const purpleDark500 = purpleDarkBase;
export const purpleDark600 = '#927FCD';
export const purpleDark700 = '#B09FE2';
export const purpleDark800 = '#AFA5D0';

// Yellow Light
export const yellowLightBase = '#D7C46B';
export const yellowLight50 = '#31302A';
export const yellowLight100 = '#4E4A35';
export const yellowLight200 = '#80743D';
export const yellowLight300 = '#B2A255';
export const yellowLight400 = '#C8B661';
export const yellowLight500 = yellowLightBase;
export const yellowLight600 = '#E3D283';
export const yellowLight700 = '#FFEFA2';
export const yellowLight800 = '#FFF3BC';

// Yellow
export const yellowBase = '#FFC738';
export const yellow50 = '#2F2B25';
export const yellow100 = '#463D27';
export const yellow200 = '#80641C';
export const yellow300 = '#B28B27';
export const yellow400 = '#DBAB30';
export const yellow500 = yellowBase;
export const yellow600 = '#FFD56A';
export const yellow700 = '#FDDD8C';
export const yellow800 = '#FCE5AC';

// Yellow Dark
export const yellowDarkBase = '#AD5A0F';
export const yellowDark50 = '#2E2722';
export const yellowDark100 = '#44301F';
export const yellowDark200 = '#663305';
export const yellowDark300 = '#8F4707';
export const yellowDark400 = '#9E4F08';
export const yellowDark500 = yellowDarkBase;
export const yellowDark600 = '#D98C47';
export const yellowDark700 = '#DB9454';
export const yellowDark800 = '#E5B284';

export const neutralAlpha50 = alpha(gray1100, 0.25);
export const neutralAlpha100 = alpha(gray1100, 0.35);
export const neutralAlpha500 = alpha(gray1100, 0.5);
export const neutralAlpha700 = alpha(gray1100, 0.6);
export const neutralAlpha900 = alpha(gray1100, 0.95);
